<template>
  <div>
    <router-view ref="rvs" />
    <div v-show="isExactActive">
      <b-overlay :show="loading" variant="transparent" no-wrap> </b-overlay>
      <div class="navbarCustom">
        <div class="logo-left" style="margin-left: 5px">
          <img
            class="logo_ic"
            src="@/assets/icons/back_ic.svg"
            @click="$router.go(-1)"
          />
          <span class="nav_title">Kategoriya</span>
        </div>
      </div>
      <div class="array-card" style="margin-top: 50px">
        <b-container class="vipSongs">
          <b-row>
            <b-col cols="6" v-for="cats in cats" :key="cats.cid">
              <router-link
                :to="{
                  name: 'cat',
                  params: {
                    id: cats.cid,
                    title: song_detail(cats.category_name),
                    page: 1,
                  },
                  query: { on: cats.category_name },
                }"
              >
                <div class="card-pl" :style="{ background: cats.cat_color }">
                  <div class="card-tv">{{ cats.category_name }}</div>
                  <progressive-img
                    class="card-img"
                    :blur="0"
                    placeholder="https://alemsesi.com/api/placeholder.webp"
                    fallback="https://alemsesi.com/api/placeholder.webp"
                    :src="cats.cat_icon"
                  />
                </div>
              </router-link>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "category",
  data() {
    return {
      loaded: true,
      loading: false,
      cats: "",
      pages: 1,
      isExactActive: true,
    };
  },
  updated() {
    this.isExactActive = typeof this.$refs.rvs === "undefined";
  },
  async beforeMount() {
    this.loading = true;
    this.loaded = false;

    try {
      const headers = new Headers({
          'Authorization': 'Basic ' + this.$store.getters.token,
        });
      const response = await fetch(
        "https://alemsesi.com/api/wep_api.php?" +
          "cat_list" +
          "&page=" +
          this.pages
      ,{headers});
      const data = await response.json();
      this.cats = data.ONLINE_MP3;
      this.loading = false;
      this.loaded = true;
    } catch (error) {
      console.log(error);
      this.loaded = false;
      this.loading = false;
    }
  },
  methods: {
    song_detail(title) {
      var slug = "";
      var titleLower = title.toLowerCase();
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, "e");
      slug = slug.replace(/a|á|à|ã|ả|ạ|ä|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, "a");
      slug = slug.replace(/o|ó|ò|õ|ỏ|ö|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, "o");
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ü|ử|ự/gi, "u");
      slug = slug.replace(/c|ç/gi, "c");
      slug = slug.replace(/n|ň/gi, "n");
      slug = slug.replace(/s|ş/gi, "s");
      slug = slug.replace(/y|ÿ|ý/gi, "y");
      slug = slug.replace(/đ/gi, "d");
      slug = slug.replace(/\s*$/g, "");
      slug = slug.replace(/ /g, "-");

      return slug;
    },
  },
  mounted() {
    this.isExactActive = typeof this.$refs.rvs === "undefined";
  },
};
</script>
