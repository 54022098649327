var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-view',{ref:"rvs"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExactActive),expression:"isExactActive"}]},[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":"transparent","no-wrap":""}}),_c('div',{staticClass:"navbarCustom"},[_c('div',{staticClass:"logo-left",staticStyle:{"margin-left":"5px"}},[_c('img',{staticClass:"logo_ic",attrs:{"src":require("@/assets/icons/back_ic.svg")},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',{staticClass:"nav_title"},[_vm._v("Kategoriya")])])]),_c('div',{staticClass:"array-card",staticStyle:{"margin-top":"50px"}},[_c('b-container',{staticClass:"vipSongs"},[_c('b-row',_vm._l((_vm.cats),function(cats){return _c('b-col',{key:cats.cid,attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":{
                name: 'cat',
                params: {
                  id: cats.cid,
                  title: _vm.song_detail(cats.category_name),
                  page: 1,
                },
                query: { on: cats.category_name },
              }}},[_c('div',{staticClass:"card-pl",style:({ background: cats.cat_color })},[_c('div',{staticClass:"card-tv"},[_vm._v(_vm._s(cats.category_name))]),_c('progressive-img',{staticClass:"card-img",attrs:{"blur":0,"placeholder":"https://alemsesi.com/api/placeholder.webp","fallback":"https://alemsesi.com/api/placeholder.webp","src":cats.cat_icon}})],1)])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }